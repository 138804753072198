"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.spacing = exports.spaces = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Spacing Classes either margin/padding. Please look at spacing story for examples
 */
const spaces = []; // Generate spacing classes for 0 to 100 (inclusive)

exports.spaces = spaces;

for (let i = 0; i <= 100; i++) {
  spaces.push("pl-".concat(i), "pr-".concat(i), "pt-".concat(i), "pb-".concat(i), "ml-".concat(i), "mr-".concat(i), "mt-".concat(i), "mb-".concat(i));
}

const spacing = {
  /**
   * Margin & Padding spacing classes
   */
  spacers: _propTypes.default.arrayOf(_propTypes.default.oneOf([...spaces, ""]).isRequired).isRequired
};
exports.spacing = spacing;