import React, { useState } from "react";
import { useStore } from "../../store/Store";
import { observer } from "mobx-react-lite";
import {
  UserAvatar,
  MenuDivider,
  Text,
  modalInstance,
  AppHeaderBar,
  AppHeaderBarContentLeft,
  AppHeaderBarMenuButton,
  AppHeaderBarDivide,
  AppHeaderBarBrand,
  AppHeaderBarProfileMenu,
  AppHeaderBarDropdown,
  AppHeaderBarDropdownList,
  DropdownListItem,
  DropdownItem,
  IconLaunch,
  FusionAdmin,
  FusionLogo,
} from "@fundrecs/ui-library";
import { UserAccountSettings } from "./UserAccountSettings.js";

const Header = observer(() => {
  let { meStore, uiStore, tenantConfigStore } = useStore();
  const [activeIndex, setActiveIndex] = useState(null);

  const userName = meStore.getName();
  const userEmail = meStore.getEmail();
  const fusionDataUrl = tenantConfigStore.getFusionDataUrl();
  const fusionRecsUrl = tenantConfigStore.getFusionRecsUrl();
  const frontendBranding = tenantConfigStore.getFrontendBranding();

  return (
    <>
      <AppHeaderBar>
        <AppHeaderBarContentLeft>
          <AppHeaderBarMenuButton
            onClick={() => {
              uiStore.toggleSideMenu();
            }}
          />
          <AppHeaderBarBrand>{frontendBranding && <FusionLogo />}</AppHeaderBarBrand>
        </AppHeaderBarContentLeft>
        <AppHeaderBarDropdown
          onClick={() => {
            setActiveIndex(null);
          }}
        >
          <UserAvatar image={""} size="md" />
        </AppHeaderBarDropdown>
        <AppHeaderBarDropdownList>
          <AppHeaderBarProfileMenu name={userName} email={userEmail} />
          <MenuDivider />
          <div style={{ paddingTop: "4px", paddingBottom: "5px" }}>
            <DropdownListItem
              key={0}
              onClick={() => {
                setActiveIndex(0);
                meStore.requestMe();
                modalInstance("userAccountSettingsPanel").show();
              }}
            >
              <DropdownItem active={activeIndex} index={0}>
                <Text size="sm" weight="regular" theme="light">
                  Manage account
                </Text>
              </DropdownItem>
            </DropdownListItem>
            <DropdownListItem
              key={1}
              onClick={() => {
                setActiveIndex(1);
                window.open(fusionDataUrl, "_blank");
              }}
            >
              <DropdownItem active={activeIndex} index={1}>
                <Text size="sm" weight="regular" theme="light">
                  Launch fusion
                </Text>
                <div style={{ marginLeft: "auto" }}>
                  <IconLaunch className="icon-size" />
                </div>
              </DropdownItem>
            </DropdownListItem>
            <DropdownListItem
              key={2}
              onClick={() => {
                setActiveIndex(2);
                window.location.href = "/logout";
              }}
            >
              <DropdownItem active={activeIndex} index={2}>
                <Text size="sm" weight="regular" theme="light">
                  Log out
                </Text>
              </DropdownItem>
            </DropdownListItem>
          </div>
        </AppHeaderBarDropdownList>
      </AppHeaderBar>
      <AppHeaderBarDivide />

      <UserAccountSettings />
    </>
  );
});

export { Header };
